import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { FaArrowCircleLeft, FaArrowCircleRight } from 'react-icons/fa';
import React from 'react';
import { createOrder } from './util/api';
class Card extends React.Component {

    state = {
        activeIndex: 1,
        amount: 150,
        itemCount: 1,
        dispenser: 1
    };
    updateDispenserNumber = (direction) => {
        let dispenserNo = this.state.dispenser
        direction === "left" ? dispenserNo-- : dispenserNo++
        console.log("updateDispenserNumber")
        this.setState({ dispenser: dispenserNo });
    }
    updateRadioGroup = (index, amt, count) => {
        this.setState({ activeIndex: index, amount: amt, itemCount: count });
    }
    onPayClick = async () => {
        console.log("active index", this.state.activeIndex)
        console.log("amount amount", this.state.amount)

        let createOrderResponse = await createOrder(this.state.amount, this.state.dispenser, this.state.itemCount)

        console.log(createOrderResponse);
        // if (createOrderResponse.returnCode === 200) {
            window.location.href = `https://wevend.world/${createOrderResponse.paymentOrderId}/gumball`
        // }
        // www.test.com/?a=${queryA}&b=${queryB}`
    }
    render() {
        let { activeIndex } = this.state;
        console.log("this state", this.state)
        return (
            <div className="slider_wrapper">
                <div className="inner">
                    <Carousel showThumbs={false} showIndicators={false} showStatus={false}
                        renderArrowPrev={(clickHandler, hasPrev) => {
                            return (
                                <div
                                    className={`${hasPrev ? 'absolute' : 'hidden'
                                        } top-0 bottom-0 left-0 flex justify-center items-center p-3 opacity-30 hover:opacity-100 cursor-pointer z-20 blueColor`}
                                    onClick={() => {
                                        this.updateDispenserNumber("left")
                                        clickHandler()
                                    }
                                    }
                                >
                                    <FaArrowCircleLeft className="w-9 h-9 text" />
                                    {/* <button type="button" role="presentation" className="w-9 h-9 newCls"></button> */}
                                </div>
                            );
                        }}
                        renderArrowNext={(clickHandler, hasNext) => {
                            return (
                                <div
                                    className={`${hasNext ? '' : 'hidden'
                                        } absolute top-0 bottom-0 right-0 flex justify-center items-center p-3 opacity-30 hover:opacity-100 cursor-pointer z-20 blueColor`}
                                    onClick={() => {
                                        this.updateDispenserNumber("right")
                                        clickHandler()
                                    }
                                    }
                                >
                                    <FaArrowCircleRight className="w-9 h-9 text" />
                                </div>
                            );
                        }}
                    >
                        <div className="slider_card">
                            <h4>Dispenser 1</h4>
                            <div className="image_block"><img src="placeholder.png" alt=""></img></div>
                            <div className="price_wrapper">
                                <div key={1} className={activeIndex === 1 ? 'block active' : 'block'} onClick={() => this.updateRadioGroup(1, 150, 1)}>1 = $1.50</div>
                                <div key={2} className={activeIndex === 2 ? 'block active' : 'block'} onClick={() => this.updateRadioGroup(2, 500, 5)}>5 = $5.00</div>
                            </div>
                        </div>
                        <div className="slider_card">
                            <h4>Dispenser 2</h4>
                            <div className="image_block"><img src="placeholder.png" alt=""></img></div>
                            <div className="price_wrapper">
                                <div key={1} className={activeIndex === 1 ? 'block active' : 'block'} onClick={() => this.updateRadioGroup(1, 150, 1)}>1 = $1.50</div>
                                <div key={2} className={activeIndex === 2 ? 'block active' : 'block'} onClick={() => this.updateRadioGroup(2, 500, 5)}>5 = $5.00</div>
                            </div>
                        </div>
                        <div className="slider_card">
                            <h4>Dispenser 3</h4>
                            <div className="image_block"><img src="placeholder.png" alt=""></img></div>
                            <div className="price_wrapper">
                                <div key={1} className={activeIndex === 1 ? 'block active' : 'block'} onClick={() => this.updateRadioGroup(1, 150, 1)}>1 = $1.50</div>
                                <div key={2} className={activeIndex === 2 ? 'block active' : 'block'} onClick={() => this.updateRadioGroup(2, 500, 5)}>5 = $5.00</div>
                            </div>
                        </div>
                        <div className="slider_card">
                            <h4>Dispenser 4</h4>
                            <div className="image_block"><img src="placeholder.png" alt=""></img></div>
                            <div className="price_wrapper">
                                <div key={1} className={activeIndex === 1 ? 'block active' : 'block'} onClick={() => this.updateRadioGroup(1, 150, 1)}>1 = $1.50</div>
                                <div key={2} className={activeIndex === 2 ? 'block active' : 'block'} onClick={() => this.updateRadioGroup(2, 500, 5)}>5 = $5.00</div>
                            </div>
                        </div>
                        <div className="slider_card">
                            <h4>Dispenser 5</h4>
                            <div className="image_block"><img src="placeholder.png" alt=""></img></div>
                            <div className="price_wrapper">
                                <div key={1} className={activeIndex === 1 ? 'block active' : 'block'} onClick={() => this.updateRadioGroup(1, 150, 1)}>1 = $1.50</div>
                                <div key={2} className={activeIndex === 2 ? 'block active' : 'block'} onClick={() => this.updateRadioGroup(2, 500, 5)}>5 = $5.00</div>
                            </div>
                        </div>
                        <div className="slider_card">
                            <h4>Dispenser 6</h4>
                            <div className="image_block"><img src="placeholder.png" alt=""></img></div>
                            <div className="price_wrapper">
                                <div key={1} className={activeIndex === 1 ? 'block active' : 'block'} onClick={() => this.updateRadioGroup(1, 150, 1)}>1 = $1.50</div>
                                <div key={2} className={activeIndex === 2 ? 'block active' : 'block'} onClick={() => this.updateRadioGroup(2, 500, 5)}>5 = $5.00</div>
                            </div>
                        </div>
                        <div className="slider_card">
                            <h4>Dispenser 7</h4>
                            <div className="image_block"><img src="placeholder.png" alt=""></img></div>
                            <div className="price_wrapper">
                                <div key={1} className={activeIndex === 1 ? 'block active' : 'block'} onClick={() => this.updateRadioGroup(1, 150, 1)}>1 = $1.50</div>
                                <div key={2} className={activeIndex === 2 ? 'block active' : 'block'} onClick={() => this.updateRadioGroup(2, 500, 5)}>5 = $5.00</div>
                            </div>
                        </div>
                        <div className="slider_card">
                            <h4>Dispenser 8</h4>
                            <div className="image_block"><img src="placeholder.png" alt=""></img></div>
                            <div className="price_wrapper">
                                <div key={1} className={activeIndex === 1 ? 'block active' : 'block'} onClick={() => this.updateRadioGroup(1, 150, 1)}>1 = $1.50</div>
                                <div key={2} className={activeIndex === 2 ? 'block active' : 'block'} onClick={() => this.updateRadioGroup(2, 500, 5)}>5 = $5.00</div>
                            </div>
                        </div>
                        <div className="slider_card">
                            <h4>Dispenser 9</h4>
                            <div className="image_block"><img src="placeholder.png" alt=""></img></div>
                            <div className="price_wrapper">
                                <div key={1} className={activeIndex === 1 ? 'block active' : 'block'} onClick={() => this.updateRadioGroup(1, 150, 1)}>1 = $1.50</div>
                                <div key={2} className={activeIndex === 2 ? 'block active' : 'block'} onClick={() => this.updateRadioGroup(2, 500, 5)}>5 = $5.00</div>
                            </div>
                        </div>
                    </Carousel>
                </div>
                <a className="paybutton" onClick={this.onPayClick}>Pay</a>
            </div>

        );
    }
}

export default Card;
