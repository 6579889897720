import './App.css';
import Card from './Card'
function App() {
  return (
    <div className="main_wrapper">
      <header><a href="#"><img src="logo.png" alt="" /></a></header>
      <Card />
      <footer><img src="footer-logo.png" alt="" /></footer>
    </div>
  );
}

export default App;
