export async function getTransactionDetails(transactionId) {
    console.log("transactionId", transactionId)
    let url = "https://muqfdnbqnb.execute-api.ap-south-1.amazonaws.com/dev/gettransactiondetails";
    // let url = "https://muqfdnbqnb.execute-api.ap-south-1.amazonaws.com/dev/getorderdetails";
    let fetchTransactionResponse = await fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            transactionId: transactionId,
        }),
    });
    console.log("fetchTransactionResponse0", fetchTransactionResponse);

    let res = await fetchTransactionResponse.json();
    console.log(res);
    return res.amount;
}
export async function getOrderDetails(paymentOrderId) {
    console.log("paymentOrderId", paymentOrderId)
    // let url = "https://muqfdnbqnb.execute-api.ap-south-1.amazonaws.com/dev/gettransactiondetails";
    let url = "https://muqfdnbqnb.execute-api.ap-south-1.amazonaws.com/dev/getorderdetails";
    let fetchTransactionResponse = await fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            paymentOrderId
        }),
    });
    console.log("fetchTransactionResponse0", fetchTransactionResponse);

    let res = await fetchTransactionResponse.json();
    console.log(res);
    return res
}
export async function createOrder(amount, dispenserNo, itemCount) {
    let createOrderResponse = await fetch(
        "https://muqfdnbqnb.execute-api.ap-south-1.amazonaws.com/dev/createorder",
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "mid": "RCTST1000094603",
                "orderId": 1,
                "termId": 3,
                "amount": amount/100,
                "metaData": { dispenserNo, itemCount, amount }
            }),
        }
    );
    return await createOrderResponse.json();


}