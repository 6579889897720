import React from 'react'
import { useParams } from "react-router-dom";

const PaymentFailurePAge = () => {
    const { code } = useParams();
    return (
        <div className="main_wrapper">
            <header><a ><img src="/logo.png" alt="" /></a></header>
            <div className='/paymentFailImageHolder'>
                <img src="/payment-failed.gif" alt="" />
                <h5 style={{ textAlign: "center" }}>Failed to make payment, please try again</h5>
                <h5 style={{ textAlign: "center" }}>Code: {code}</h5>
            </div>
            <footer><img src="/footer-logo.png" alt="" /></footer>
        </div>
    )
}

export default PaymentFailurePAge